import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import menu from './menu'
import layout from '../layout/layout.vue'
import { storage } from '@/utils/storage'

let routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: layout,
    redirect: '/order',
    children: menu.concat([
      {
        path: 'customer/detail/:id',
        name: 'customerDetail',
        component: () => import('../views/customer/customerDetail.vue')
      },
      {
        path: 'order/orders/detail/:id',
        name: 'ordersDetail',
        component: () => import('../views/orders/ordersDetail.vue')
      },
      {
        path: 'order/golden/detail/:id',
        name: 'goldenOrdersDetail',
        component: () => import('../views/orders/goldenOrdersDetail.vue')
      },
      {
        path: 'channel/detail/:id',
        name: 'channelDetail',
        component: () => import('../views/channel/channelDetail.vue')
      },
      {
        path: 'provider/detail/:id',
        name: 'providerDetail',
        component: () => import('../views/provider/providerDetail.vue')
      },
      {
        path: 'golden/detail/:id',
        name: 'goldenDetail',
        component: () => import('../views/golden/goldenDetail.vue')
      }
    ])
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !storage.get(process.env.VUE_APP_COOKIE_KEY)) next({ name: 'login' })
  else next()
})

export default router
