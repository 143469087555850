import {RouteRecordRaw} from "vue-router";
import layout from "@/layout/layout.vue";

const menuList: Array<RouteRecordRaw> = [
    {
        path: '/order',
        name: 'order',
        redirect: '/order/orders',
        meta: {
            label: '订单管理',
            icon: 'BookOutlined'
        },
        children: [
            {
                path: '/order/orders',
                name: 'orders',
                component: () => import('@/views/orders/orders.vue'),
                meta: {
                    label: '渠道订单',
                    keepAlive: true
                }
            },
            {
                path: '/order/golden',
                name: 'orderGolden',
                component: () => import('@/views/orders/goldenOrders.vue'),
                meta: {
                    label: '红人订单',
                    keepAlive: true
                }
            }
        ]
    },
    {
        path: '/customer',
        name: 'customer',
        component: () => import('@/views/customer/customer.vue'),
        meta: {
            label: '客户管理',
            icon: 'TeamOutlined',
            keepAlive: true
        }
    },
    {
        path: '/channel',
        name: 'channel',
        component: () => import('@/views/channel/channel.vue'),
        meta: {
            label: '渠道管理',
            icon: 'DeploymentUnitOutlined'
        }
    },
    {
        path: '/provider',
        name: 'provider',
        component: () => import('@/views/provider/provider.vue'),
        meta: {
            label: '代发管理',
            icon: 'SendOutlined'
        }
    },
    {
        path: '/golden',
        name: 'golden',
        component: () => import('@/views/golden/golden.vue'),
        meta: {
            label: '红人管理',
            icon: 'SmileOutlined'
        }
    }
]

export default menuList
