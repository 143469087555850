import { createStore } from 'vuex'
import API from '@/api'
import { storage } from '@/utils/storage'

export default createStore({
  state: {
    user: {
      token: '',
      role: ''
    },
    loading: false, // 页面级loading
    channelFormModal: false,
    platformOptions: [
      {'value': 'amazon', 'label': '亚马逊'},
      {'value': 'walmart', 'label': '沃尔玛'},
      {'value': 'aliexpress', 'label': '速卖通'},
      {'value': 'self', 'label': '独立站'},
      {'value': 'other', 'label': '其他'}
    ],
    regionOptions: [
      {"value":"US", "label": "美国"},
      {"value":"UK", "label": "英国"},
      {"value":"AU", "label": "澳大利亚"},
      {"value":"BR", "label": "巴西"},
      {"value":"CA", "label": "加拿大"},
      {"value":"DE", "label": "德国"},
      {"value":"ES", "label": "西班牙"},
      {"value":"FR", "label": "法国"},
      {"value":"IL", "label": "以色列"},
      {"value":"IT", "label": "意大利"},
      {"value":"JP", "label": "日本"},
      {"value":"MX", "label": "墨西哥"},
      {"value":"PL", "label": "波兰"},
      {"value":"RU", "label": "俄罗斯"}
    ],
    dollarOptions: {
      AU: 'AUD$',
      BR: 'BRL$',
      CA: 'CAD$',
      DE: 'EUR€',
      ES: 'EUR€',
      FR: 'EUR€',
      IL: 'ILS₪ ',
      IT: 'EUR€',
      JP: 'JPY￥',
      MX: 'MXN$',
      PL: 'PLNzł',
      RU: 'RUB₽',
      UK: 'GBP￡',
      US: 'USD$'
    }
  },
  getters: {
    token: state => state.user.token,
    role: state => state.user.role
  },
  mutations: {
    SET_USER: (state, user) => {
      const key = process.env.VUE_APP_COOKIE_KEY
      if (user.token) {
        storage.set(key, user)
      } else {
        storage.remove(key)
      }
      state.user = user
    },
    changeModal (state: any, params) {
      state[params] = !state[params]
    },
    setLoading (state, active) {
      state.loading = active
    }
  },
  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        API.login(userInfo).then(response => {
          if (response.res_code === 200) {
            const result = response.data
            // Vue.ls.set('USER_INFO', result, 7 * 24 * 60 * 60 * 1000)
            commit('SET_USER', result)
            resolve(response)
          } else {
            reject(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    Logout ({ commit }) {
      commit('SET_USER', {})
      window.location.reload()
    }
  },
  modules: {
  }
})
