import axios from 'axios'
import store from '@/store/index'
import { message } from 'ant-design-vue';
// 创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 10000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
    let token = store.getters.token
    // 统一处理token
    if (token) {
        config!.headers!.Authorization = token // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    store.commit('setLoading', true)
    return config
}, error => {
    Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
    response => {
        /**
         * code为非200是抛错 可结合自己业务进行修改
         */
        let res = typeof response.data === 'string' ? JSON.parse(response!.data) : response.data
        store.commit('setLoading', false)
        if (response.status === 200) {
            if (res.res_code === 200) {
                return res
            } else {
                if (res.err_code.indexOf('TOKEN') > -1 || res.err_code.indexOf('INVALID_LOGIN') > -1) {
                    store.dispatch('Logout')
                }
                message.warning(res.err_msg || '服务器繁忙，请稍后再试')
                return Promise.reject(res)
            }
        } else {
            message.warning(res.err_msg || '服务器繁忙，请稍后再试')
            return Promise.reject(res)
        }
    },
    error => {
        store.commit('setLoading', false)
        if (error && error.response && error.response.status) {
            if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
                message.warning('网络异常，请检查网络后重试')
            } else {
                message.warning('网络异常，请稍后重试！')
            }
        } else {
            message.warning('网络未连接，请连接网络后重试')
        }
        return Promise.reject(error)
    }
)

export default service
