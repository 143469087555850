import request from './request'

export default {
    // 登录
    login (data: any) {
        return request({
            url: '/op/login',
            method: 'post',
            data: data
        })
    },
    // 上传图片预签名
    uploadPresign (data: any) {
        return request({
            url: '/upload/presign',
            method: 'post',
            data: data
        })
    },
    // 客户管理列表
    clientList (data: any) {
        return request({
            url: '/op/client/list',
            method: 'post',
            data: data
        })
    },
    // 客户详情
    clientDetail (id: any) {
        return request({
            url: '/op/client/' + id,
            method: 'get',
            data: {}
        })
    },
    // 订单所属代发筛选条件
    orderProviders () {
        return request({
            url: '/op/order/providers',
            method: 'get',
            data: {}
        })
    },
    // 订单管理列表
    orderList (data: any) {
        return request({
            url: '/op/order/list',
            method: 'post',
            data: data
        })
    },
    // 订单详情
    orderDetail (id: any) {
        return request({
            url: '/op/order/' + id,
            method: 'get',
            data: {}
        })
    },
    // 订单接单
    orderAccept (id: string) {
        return request({
            url: '/op/order/accept/' + id,
            method: 'get',
            data: {}
        })
    },
    // 订单退单
    orderReject (data: any) {
        return request({
            url: '/op/order/reject/' + data.id,
            method: 'post',
            data: data
        })
    },
    // 订单代发附件提交
    orderAsset (data: any) {
        return request({
            url: '/op/order/asset/' + data.id,
            method: 'post',
            data: data
        })
    },
    // 渠道列表
    channelList (data: any) {
        return request({
            url: '/op/channel/list',
            method: 'post',
            data: data
        })
    },
    // 渠道上下架
    channelPut (id: string) {
        return request({
            url: '/op/channel/' + id,
            method: 'delete',
            data: {}
        })
    },
    // 渠道详情
    channelDetail (id: string) {
        return request({
            url: '/op/channel/' + id,
            method: 'get',
            data: {}
        })
    },
    // 新增渠道
    addChannel (data: any) {
        return request({
            url: '/op/channel',
            method: 'post',
            data: data
        })
    },
    // 修改渠道
    editChannel (data: any) {
        return request({
            url: '/op/channel/' + data.id,
            method: 'put',
            data: data
        })
    },
    // 代发管理列表
    providerList (data: any) {
        return request({
            url: '/op/provider/list',
            method: 'post',
            data: data
        })
    },
    // 代发管理详情
    providerDetail (id: string) {
        return request({
            url: '/op/provider/' + id,
            method: 'get',
            data: {}
        })
    },
    // 代发关联渠道列表
    providerChannelList (data: any) {
        return request({
            url: '/op/provider/channel/list',
            method: 'post',
            data: data
        })
    },
    // 代发关联渠道详情
    providerChannelDetail (id: string) {
        return request({
            url: '/op/provider/channel/' + id,
            method: 'get',
            data: {}
        })
    },
    // 代发取消与渠道关联
    providerConnect (data: any) {
        return request({
            url: '/op/provider/channel/connect',
            method: 'post',
            data: data
        })
    },
    // 根据渠道编号查询渠道信息
    providerSearch (id: string) {
        return request({
            url: '/op/provider/search/' + id,
            method: 'get',
            data: {}
        })
    },
    // 新增代发渠道
    addProviderChannel (data: any) {
        return request({
            url: '/op/provider/channel',
            method: 'post',
            data: data
        })
    },
    // 修改代发渠道信息
    editProviderChannel (data: any) {
        return request({
            url: '/op/provider/channel/' + data.connect_id,
            method: 'put',
            data: data
        })
    },
    // 红人管理列表
    goldenList (data: any) {
        return request({
            url: '/op/influencer/resource/list',
            method: 'post',
            data: data
        })
    },
    // 红人上下架
    goldenPut (id: string) {
        return request({
            url: '/op/influencer/resource/one/' + id,
            method: 'delete',
            data: {}
        })
    },
    // 红人详情
    goldenDetail (id: string) {
        return request({
            url: '/op/influencer/resource/one/' + id,
            method: 'get',
            data: {}
        })
    },
    // 新增红人
    addGolden (data: any) {
        return request({
            url: '/op/influencer/resource/one',
            method: 'post',
            data: data
        })
    },
    // 修改红人
    editGolden (data: any) {
        return request({
            url: '/op/influencer/resource/one/' + data.id,
            method: 'put',
            data: data
        })
    },
    goldenDict () {
        return request({
            url: '/influencer/matter',
            method: 'get',
            data: {}
        })
    },
    // 红人订单列表
    goldenOrderList (data: any) {
        return request({
            url: '/op/influencer/list',
            method: 'post',
            data: data
        })
    },
    // 红人订单详情
    goldenOrderDetail (id: any) {
        return request({
            url: '/op/influencer/one/' + id,
            method: 'get',
            data: {}
        })
    },
    // 红人订单接单
    goldenOrderAccept (id: string) {
        return request({
            url: '/op/influencer/accept/' + id,
            method: 'get',
            data: {}
        })
    },
    // 红人订单退款
    goldenOrderReject (data: any) {
        return request({
            url: '/op/influencer/refund/' + data.id,
            method: 'post',
            data: data
        })
    },
    // 红人订单上传链接
    goldenOrderAsset (data: any) {
        return request({
            url: '/op/influencer/asset/' + data.id,
            method: 'post',
            data: data
        })
    },
    // 添加备注
    addGoldenRemark (data: any) {
        return request({
            url: '/op/influencer/remark/' + data.id,
            method: 'post',
            data: data
        })
    },
    // 删除备注
    deleteGoldenRemark (id: any) {
        return request({
            url: '/op/influencer/remark/' + id,
            method: 'delete',
            data: {}
        })
    },
}
