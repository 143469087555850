
import { defineComponent, reactive, toRefs, onMounted, watch, unref, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore, } from 'vuex'
import menuList from '@/router/menu'
export default defineComponent({
  setup() {
    const menuState = reactive({
      selectedKeys: ['/customer'],
      openKeys: ['/order'],
    });
    const store = useStore()
    let route = useRoute()
    let router = useRouter()
    const { currentRoute } = useRouter()

    const rootSubMenuKeys: string[] = [];
    for (const { children, path } of menuList) {
      if (children && children.length > 0) {
        rootSubMenuKeys.push(path);
      }
    }

    onMounted(() => {
      nextTick(() => {
        handleMenuChange()
      });
    });

    watch(() => route.path,val => {
        //当路由变化时，拿到当前路由所在模块的key
        if (val) {
          handleMenuChange()
        }
      }
    );

    function onOpenChange(openKeys: string[]) {
      const latestOpenKey = openKeys.find(key => {
        return menuState.openKeys.indexOf(key) === -1
      });
      if (rootSubMenuKeys.indexOf(latestOpenKey!) === -1) {
        menuState.openKeys = openKeys;
      } else {
        menuState.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    }

    async function handleMenuClick({ item, key }: { item: any; key: string; keyPath: string[] }) {
      await router.push(key)
      menuState.selectedKeys = [key];
    }

    function handleMenuChange() {
      const path = unref(currentRoute).path
      console.log(unref(currentRoute))
      let r = path.split('/')
      menuState.openKeys = ['/' + r[1]]
      // 有待优化，想做到一级菜单、二级菜单以及各自的详情页都可以选择菜单，目前对路由配置要求较高
      menuState.selectedKeys = [(r.length > 4 || r.length === 3) ? ('/' + r[1] + '/' + r[2]) : '/' + r[1]]
    }

    function logout() {
      store.dispatch('Logout')
    }

    return {
      ...toRefs(menuState),
      route,
      handleMenuClick,
      logout,
      store,
      menuList,
      onOpenChange
    };
  },
});
