
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { defineComponent, onMounted } from 'vue';
import { useStore } from 'vuex';
import { storage } from '@/utils/storage'
dayjs.locale('zh');
export default defineComponent({
  setup() {
    const store = useStore()
    const key = process.env.VUE_APP_COOKIE_KEY
    onMounted (() => {
      let user = storage.get(process.env.VUE_APP_COOKIE_KEY)
      if (user) {
        store.commit('SET_USER', user)
      }
    })
    return {
      zhCN
    }
  }
});
